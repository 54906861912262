export const IMAGE_HOST = 'images.grove.co'

/**
 * Transform an image path using the Cloudinary image transformation service.
 * Leading double forward slashes in the imagePath will be ignored.
 * Any existing transformation parameters in the imagePath will be replaced.
 * @see https://cloudinary.com/documentation/image_transformation_reference
 *
 * @param imagePath Absolute path to the image (must include include hostname and protocol)
 * @param transformations A comma delineated string list of transforms
 * @returns
 */
export function transformImage(
  imagePath: string | URL,
  transformations: string
): string {
  // If no image path is provided, return an empty string.
  if (!imagePath) return ''

  const imageUrl = new URL(imagePath)

  // In all cases force https. We're a secure site and cannot allow insecure images
  imageUrl.protocol = 'https'

  transformations = transformations.replaceAll(' ', '')

  /**
   * Cloudinary images are now loaded and cached using a Cloudfront worker to save on bandwidth costs.
   * This replaces all references to "res.cloudinary.com/epantry/image" with "images.grove.co".
   * @see https://github.com/groveco/cloudinary-proxy
   */
  if (imageUrl.host === 'res.cloudinary.com') {
    imageUrl.host = IMAGE_HOST
    imageUrl.pathname = imageUrl.pathname.replace('/epantry/image', '')
  }

  /**
   * If the image is external, fetch it with Cloudinary's fetch service.
   * @see https://cloudinary.com/documentation/fetch_remote_images
   */
  if (imageUrl.host !== IMAGE_HOST) {
    imageUrl.pathname = `/fetch/${transformations}/${imageUrl.href}`
    imageUrl.host = IMAGE_HOST
    return imageUrl.toString()
  }

  // Split the path into fragments
  const pathFragments = imageUrl.pathname.split('/')

  // Insert the transformations after the third fragment, replacing any existing transformations.
  pathFragments.splice(
    2,
    pathFragments[2]?.match(/[a-z]{1,2}_/) ? 1 : 0,
    transformations
  )

  // Rejoin the path fragments and return the transformed image URL.
  imageUrl.pathname = pathFragments.join('/')
  return imageUrl.toString()
}
